<script setup>
const { t } = useI18n()
import { useConfigStore } from "../../stores/config"
import { useUserStore } from "../../stores/user"
import { useClaimStore } from "../../stores/claim"
const configStore = useConfigStore()
const userStore = useUserStore()
const claimStore = useClaimStore()
const route = useRoute()
const { locale, setLocale } = useI18n()

const pageTitle = ref('')
const title = computed(() => pageTitle.value)
const tabTitle = computed(() => `${pageTitle.value}—TGI`)

const setTitle = () => {
  switch(route.name) {
    case 'client-dashboard': pageTitle.value = t('dashboard.title'); break;
    case 'client-policies': pageTitle.value = t('policies.title'); break;
    case 'client-claims': pageTitle.value = t('claims.title'); break;
    case 'client-settings': pageTitle.value = t('settings.title'); break;
    case 'client-help': pageTitle.value = t('help.title'); break;
    case 'claim-view': pageTitle.value = t('claim.title'); break;
    case 'new-claim': pageTitle.value = t('newClaim.title'); break;
    default: 'Client Portal'
  }
}

watch(() => route.name, () => { setTitle() })
watch(locale, () => { setTitle() })
useHead({ title: tabTitle })
onMounted(() => {
  setTitle()
  document.addEventListener('click', handleOutsideClick)
})
onBeforeUnmount(() => {
  document.removeEventListener('click', handleOutsideClick)
})
const handleOutsideClick = (event) => {
  const dropdown = document.querySelector('.profile-dropdown')
  if (dropdown && !dropdown.contains(event.target) && profileDropdown.value) {
    profileDropdown.value = false
  }
}

// profile data
const profile = userStore.userProfile()
const hasProfileImage = () => {
  return false
}

// signout
const signOut = async () => {
  await navigateTo('/auth/logout')
}

// profile dropdown toggle (necessary for ios support)
// as safari cant handle focus states properly.
const showMenu = ref(false)
const profileDropdown = computed({
  get() { return showMenu.value },
  set(value) { showMenu.value = value }
})
const toggleProfile = () => {
  profileDropdown.value = !profileDropdown.value
}

// wrap our profile menu actions so we can close the menu
// when a user clicks an option. again, for ios support.
const doAction = (type, value=false, curLocale=null) => {
  if(type==='lang') {
    setLocale(curLocale === 'en' ? 'fr' : 'en');
    profileDropdown.value = false;
  }
  else if(type==='link') {
    profileDropdown.value = false;
    navigateTo(value)
  }
  else if(type==='signout') {
    profileDropdown.value = false;
    signOut();
  }
}

const isIOS = () => {
  return typeof navigator !== 'undefined' &&
    (/iPad|iPhone|iPod/.test(navigator.userAgent || '') ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) // iPad iOS 13
}
</script>

<template>
  <div class="flex justify-between items-center 2xl:container pb-6 relative">
    <span class="font-logo font-bold text-2xl hidden md:flex">
      <span>{{ title }}</span>
      <span v-if="route.name === 'claim-view' && route.params.claimId">: {{ route.params.claimId }}</span>
      <span v-if="route.name === 'new-claim' && claimStore.claimType">: {{ properWords(configStore.claimLabel(claimStore.claimType)) }}</span>
    </span>
    <!-- logo toggle: @click.prevent="configStore.app.newLogo = !configStore.app.newLogo" -->
    <a href="/" class="flex align-middle items-center justify-center md:hidden">
      <div v-if="configStore && configStore.app.newLogo" class="flex align-middle items-center justify-center">
        <img class="img-fluid" src="/assets/images/tgi-logo-new.svg" alt="TGI" width="70" />
        <span class="flex flex-col font-semibold font-logo leading-none tracking-tighter m-0 p-0 text-3xl ml-1 self-center text-primary">TGI</span>
      </div>
      <div v-else>
        <img class="img-fluid" src="/assets/images/tgi-logo-light.svg" alt="TGI" width="120" />
      </div>
    </a>
    <div class="flex flex-row gap-2 relative">
      <!-- iOS devices -->
      <button v-if="isIOS()" @click.stop="toggleProfile"
        class="hover:bg-gray-100 p-2.5 relative group transition-all duration-200 w-max h-max flex flex-row items-center justify-center gap-2 rounded-lg">
        <div class="pointer-events-none flex items-center justify-center text-white w-10 h-10 rounded-full bg-primary border-[3px]"
          :class="{ 'border-gray-200': hasProfileImage(), 'border-blue-200': !hasProfileImage() }">
          <img v-if="hasProfileImage()" :src="profile.image" alt="" class="w-full h-full object-cover">
          <span v-else>{{ profile.abbr }}</span>
        </div>
        <span class="pointer-events-none">{{ profile.short }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"
          class="pointer-events-none transition-transform duration-200 transform group-focus:rotate-90">
          <path fill="currentColor" d="M10.8 12L6.9 8.1Q6.625 7.825 6.625 7.4T6.9 6.7Q7.175 6.425 7.6 6.425T8.3 6.7L12.9 11.3Q13.2 11.6 13.2 12T12.9 12.7L8.3 17.3Q8.025 17.575 7.6 17.575T6.9 17.3Q6.625 17.025 6.625 16.6T6.9 16.3Z" />
        </svg>
        <div v-if="profileDropdown"
          class="profile-dropdown absolute shadow-lg top-16 left-0 w-full h-max p-2 bg-white border border-zinc-200 rounded-lg flex flex-col gap-2 z-50">
          <span v-if="configStore.app.profileMenu.lang" @click.stop="doAction('lang', null, locale)"
            class="flex flex-row gap-2 items-center hover:bg-zinc-100 p-2 rounded-lg">
            <fa :icon="['fal', 'language']" class="pointer-events-none" />
            <p class="pointer-events-none">{{ locale==='en' ? 'Français' : 'English' }}</p>
          </span>
          <span v-if="configStore.app.profileMenu.settings" @click.stop="doAction('link', '/client/settings')"
            class="flex flex-row gap-2 items-center hover:bg-zinc-100 p-2 rounded-lg">
            <fa :icon="['fal', 'gear']" />
            <p>Settings</p>
          </span>
          <span @click.stop="doAction('signout')" class="flex flex-row gap-2 items-center hover:bg-zinc-100 p-2 rounded-lg">
            <fa :icon="['fal', 'power-off']" />
            <p>Sign out</p>
          </span>
        </div>
      </button>
      <!-- The rest of the civilized world -->
      <button v-else
        class="hover:bg-gray-100 p-2.5 relative group transition-all duration-200 focus:overflow-visible w-max h-max overflow-hidden flex flex-row items-center justify-center gap-2 rounded-lg">
        <div class="flex items-center justify-center text-white w-10 h-10 rounded-full overflow-hidden bg-primary border-[3px]"
          :class="{ 'border-gray-200': hasProfileImage(), 'border-blue-200': !hasProfileImage() }">
          <img v-if="hasProfileImage()" :src="profile.image" alt="" class="w-full h-full object-cover">
          <span v-else>{{ profile.abbr }}</span>
        </div>
        <span>{{ profile.short }}</span>
        <svg class="rotate-90 group-focus:rotate-180" xmlns="http://www.w3.org/2000/svg" width="22" height="22"
          viewBox="0 0 24 24">
          <path fill="currentColor"
            d="m12 10.8l-3.9 3.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l4.6-4.6q.3-.3.7-.3t.7.3l4.6 4.6q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275z" />
        </svg>
        <div
          class="absolute shadow-lg top-16 left-0 w-full h-max p-2 bg-white border border-zinc-200 rounded-lg flex flex-col gap-2 z-50">
          <span v-if="configStore.app.profileMenu.lang" @click="setLocale(locale==='en' ? 'fr' : 'en')" class="flex flex-row gap-2 items-center hover:bg-zinc-100 p-2 rounded-lg">
            <fa :icon="['fal', 'language']" class="pointer-events-none" />
            <p class="pointer-events-none">{{ locale==='en' ? 'Français' : 'English' }}</p>
          </span>
          <span v-if="configStore.app.profileMenu.settings" @click="navigateTo('/client/settings')" class="flex flex-row gap-2 items-center hover:bg-zinc-100 p-2 rounded-lg">
            <fa :icon="['fal', 'gear']" />
            <p>Settings</p>
          </span>
          <span @click="signOut()" class="flex flex-row gap-2 items-center hover:bg-zinc-100 p-2 rounded-lg">
            <fa :icon="['fal', 'power-off']" />
            <p>Sign out</p>
          </span>
        </div>
      </button>
    </div>
  </div>
</template>
